.contact{
    margin: .5rem;
    h1{
        margin-bottom: 0;
    }
    .contact-boxes{
        display: flex;
        justify-content: space-between;
        margin: 1rem 0 .5rem 0;
        flex-wrap: wrap;
        .box{
            margin-bottom: 1rem;
            width: calc(min(18rem,100%));
            border: 1px solid #dddddd;
            border-radius: .5rem;
            padding: 1rem;
            display: flex;
            .icon{
                margin-right: 1rem;
                width: 3rem;
                height: 3rem;
                min-width: 3rem;
                min-height: 3rem;
                border-radius: 3rem;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #1da1f2;
                i{
                    font-size: 1.5rem;
                    color: #ffffff;
                }
            }
            .text{
                display: flex;
                justify-content: center;
                align-items: center;
                word-break: break-word;
                a{
                    color: #000000;
                }
            }
            &.address{
                .icon{
                    background-color: rgba(243, 156, 18, 0.2);
                    i{
                        color: rgba(243, 156, 18, 1);
                    }
                }
            }
            &.email{
                .icon{
                    background-color: rgba(46, 204, 113, 0.2);
                    i{
                        color: rgba(46, 204, 113, 1);
                    }
                }
            }
            &.phone{
                .icon{
                    background-color: rgba(142, 68, 173, 0.2);
                    i{
                        color: rgba(142, 68, 173, 1);
                    }
                }
            }
        }
    }
    .form{
        .label{
            margin-bottom: 1rem;
            .text{
                margin-bottom: .5rem;
            }
        }
    }
}