.cover-image{
    margin: .5rem;
    width: calc(100% - 1rem);
    .cover-image-inside{
        width: 100%;
        background-color: #333333;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        overflow: hidden;
        img{
            width: 60%;
            z-index: 99;
        }
        .background{
            z-index: 98;
            top: -1rem;
            left: -1rem;
            position: absolute;
            width: 120%;
            height: 120%;
            background-size: cover;
            background-position: center;
            filter: blur(8px);
        }
    }
    .caption{
        width: 100%;
        padding: .2rem 1rem;
        font-style: italic;
        text-align: center;
    }
    @media ( max-width: 1200px ) {
        .cover-image-inside{
            img{
                width: 80%;
            }
        }
    }
    @media ( max-width: 600px ) {
        .cover-image-inside{
            img{
                width: 100%;
            }
        }
    }
}