.slider .ant-carousel{
    margin: 0 0 1rem 0;
    width: calc(100%);
    overflow: hidden;
    height: 31.5rem;
    background-color: transparent;
    .slide{
        padding: .5rem;
        display: flex;
        width: 100%;
        height: 30rem;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        .title{
            -webkit-user-drag: none;
            user-select: none;
            display: inline;
            padding: 4px 8px;
            line-height:1.4;
            font-size: 20px;
            font-weight: 700;
            border-radius: 4px;
            box-decoration-break: clone;
            -webkit-box-decoration-break: clone;
            font-family: 'Asap', sans-serif;
        }
    }
    .slick-dots.slick-dots-bottom{
        padding: .4rem;
        background: #333;
        border-radius: 1rem;
        display: flex!important;
        margin: 0 auto -2rem auto;
        width: fit-content;
    }
    @media ( max-width: 1200px ){
        height: 26.5rem;
        .slide{
            height: 25rem;
            .title{
                padding: 4px 8px;
                font-size: 18px;
            }
        }
    }
    @media ( max-width: 700px ){
        height: 21.5rem;
        .slide{
            height: 20rem;
            .title{
                padding: 3px 6px;
            }
        }
    }
    @media ( max-width: 500px ){
        height: 17.5rem;
        .slide{
            height: 16rem;
            .title{
                font-size: 16px;
            }
        }
    }
    @media ( max-width: 440px ){
        height: 13.5rem;
        .slide{
            height: 12rem;
            .title{
                font-size: 14px;
            }
        }
    }
}