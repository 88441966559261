.cards{
    margin: .5rem;
    overflow: hidden;
    position: relative;
    .cards-inside{
        display: flex;
        justify-content: space-between;
        .card{
            user-select: none;
            margin: 0 1.2rem 1rem 0;
            width: 18rem;
            min-width: 18rem;
            min-height: 22rem;
            border: 1px solid #dddddd;
            display: flex;
            flex-flow: column;
            .image{
                width: 100%;
                height: 14rem;
                background-color: #333333;
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
            }
            .line{
                width: 100%;
                height: .6rem;
                background: #48b58c;
            }
            .content{
                flex: 1;
                display: flex;
                flex-flow: column;
                width: 100%;
                padding: .2rem .4rem;
                .title{
                    font-family: 'Ubuntu', sans-serif;
                    font-weight: 600;
                    font-size: 1.2rem;
                    border-bottom: 1px solid #dddddd;
                }
                .description{
                    padding: .2rem 0 .4rem 0;
                    font-size: .8rem;
                    margin-bottom: auto;
                    flex: 1;
                }
                .event{
                    display: flex;
                    .time{
                        font-size: .8rem;
                        font-weight: 600;
                    }
                    .read-more{
                        margin-right: 0;
                        cursor: pointer;
                    }
                }
            }
            &:first-child{
                margin-left: 1.2rem;
            }
        }
    }
    .arrow{
        user-select: none;
        cursor: pointer;
        border: 2px solid #dddddd;
        width: 1.6rem;
        height: 2.4rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(255,255,255,0.8);
        position: absolute;
        top: calc(50% - 1.8rem);
        &.left{
            border-radius: 0 .5rem .5rem 0;
            left: 0;
            border-left: none;
        }
        &.right{
            border-radius: .5rem 0 0 .5rem;
            right: 0;
            border-right: none;
        }
    }
}