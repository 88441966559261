.social-media-bar{
    .social-media-link{
        margin-right: 1.2rem;
        i{
            font-size: 1.2rem;
            &.fa-twitter{
                color: #1da1f2;
            }
            &.fa-instagram{
                color: #ef436f;
            }
            &.fa-youtube{
                color: #ff0000;
            }
            &.fa-telegram{
                color: #1da1f2;
            }
        }
        &.white{
            i{
                color: #ffffff!important;
            }
        }
        &:first-child{
            margin-left: .2rem;
        }
    }
}