.page-footer{
    width: 100%;
    background-color: #333333;
    color: #ffffff;
    padding-top: 1.2rem;
    padding-bottom: 2rem;
    .real-footer{
        display: flex;
        justify-content: space-between;
        padding: .4rem;
        .footer-box{
            width: 20rem;
            display: flex;
            flex-flow: column;
            align-items: flex-start;
            @media ( max-width: 21rem ) {
                width: 100%;
            }
        }
        .about-us{
            img{
                width: 85%;
                margin: .5rem 0;
            }
            .contact{
                margin-bottom: .5rem;
                .contact-box{
                    color: #ffffff;
                    display: flex;
                    margin: .4rem 0;
                    align-items: center;
                    i{
                        margin-right: .5rem;
                    }
                    .text{
                        margin-left: .5rem;
                    }
                    &.address{
                        align-items: baseline;
                    }
                }
            }
        }
        @media ( max-width: 800px ) {
            flex-flow: column-reverse;
            align-items: center;
            .about-us{
                margin-top: 1rem;
            }
        }
    }
    .credit{
        margin-top: .5rem;
        display: flex;
        justify-content: center;
        align-items: baseline;
        a{
            font-size: 1.2rem;
            font-family: 'Ubuntu', sans-serif;
            color: #ffffff;
            font-weight: 600;
            margin-right: .5rem;
        }
    }
}