.app{
    width: 100%;
    min-height: 100vh;
    background-color: #f5f5f5;
    .inner-space{
        padding-left: 12.5rem;
        padding-right: 12.5rem;
    }
    .page-loading {
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        position: fixed;
        z-index: 9999;
        background-color: #ffffff;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        .gooey {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 142px;
            height: 40px;
            margin: -20px 0 0 -71px;
            background: #fff;
            filter: contrast(20);

            .dot {
                position: absolute;
                width: 16px;
                height: 16px;
                top: 12px;
                left: 15px;
                filter: blur(4px);
                background: #000;
                border-radius: 50%;
                transform: translateX(0);
                animation: dot 2.8s infinite;
            }

            .dots {
                transform: translateX(0);
                margin-top: 12px;
                margin-left: 31px;
                animation: dots 2.8s infinite;

                span {
                    display: block;
                    float: left;
                    width: 16px;
                    height: 16px;
                    margin-left: 16px;
                    filter: blur(4px);
                    background: #000;
                    border-radius: 50%;
                }
            }
        }
        @keyframes dot {
            50% {
                transform: translateX(96px);
            }
        }
        @keyframes dots {
            50% {
                transform: translateX(-31px);
            }
        }
    }
    @for $i from 12 through 8 {
        @media ( max-width: #{$i*100}px ) {
            .inner-space{
                @if $i == 8{
                    padding-left: .8rem;
                    padding-right: .8rem;
                }
                @else{
                    padding-left: #{(12-((13-$i)*2))}rem;
                    padding-right: #{(12-((13-$i)*2))}rem;
                }
            }
        }
    }
}
@media ( max-width: 770px ) {
    .build-box{
        flex-flow: column!important;
        width: 100%!important;
        min-width: 0!important;
    }
}