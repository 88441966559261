.search-page{
    .not-found{
        width: 100%;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        img{
            width: calc(min(30rem, 100%));
        }
    }
    .results{
        width: 100%;
        .results-title{
            font-size: 1rem;
            border-bottom: 1px solid #dddddd;
            padding-bottom: .5rem;
            margin-bottom: .8rem;
        }
        .list{
            width: 100%;
            .item{
                color: #000000;
                margin-bottom: 1rem;
                width: 100%;
                display: flex;
                overflow: hidden;
                max-height: 8rem;
                .item-image{
                    width: 14rem;
                    height: 8rem;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: cover;
                    box-shadow: 0 0 5px rgba(0,0,0,0.05);
                    border-radius: .5rem;
                    margin-right: 1rem;
                }
                .item-info{
                    flex: 1;
                    display: flex;
                    flex-flow: column;
                    overflow: hidden;
                    .item-title{
                        font-weight: 700;
                        font-size: 1.5rem;
                        font-family: 'Ubuntu', sans-serif;
                        border-bottom: 1px solid #dddddd;
                    }
                    .item-description{
                        flex: 1;
                        margin-top: .5rem;
                        overflow: hidden;
                        position: relative;
                        &:after{
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            content: '';
                            width: 100%;
                            height: 100%;
                            background: rgb(149,89,89);
                            background: linear-gradient(180deg, rgba(149,89,89,0) 0%, rgba(255,255,255,1) 100%);
                        }
                    }
                }
                @media ( max-width: 700px ) {
                    max-height: 6rem!important;
                    .item-image {
                        width: 10rem!important;
                        height: 6rem!important;
                    }
                    .item-info{
                        .item-title{
                            padding-bottom: .2rem!important;
                            line-height: 1;
                            font-size: 1rem!important;
                        }
                    }
                }
                @media ( max-width: 500px ) {
                    max-height: 4.5rem!important;
                    .item-image {
                        width: 8rem!important;
                        height: 4.5rem!important;
                    }
                    .item-info{
                        .item-title{
                            margin-bottom: 0;
                        }
                        .item-description{
                            margin-top: 0;
                            font-size: .6rem!important;
                        }
                    }
                }
            }
        }
    }
}