.comments{
    border-top: 1px solid #dddddd;
    margin: 1.2rem .5rem .5rem .5rem;
    padding-top: .5rem;
    width: calc(min(100%, 40rem) - 1rem);
    .title{
        font-family: 'Ubuntu', sans-serif;
        font-weight: 600;
        font-size: 1.2rem;
    }
    .list{
        .comment{
            padding: .5rem;
            border: 1px solid #dddddd;
            border-radius: .5rem;
            margin-bottom: .5rem;
            &.reply{
                margin-left: 2rem;
            }
            .head{
                padding-bottom: .5rem;
                border-bottom: 1px dashed #dddddd;
            }
            .body{
                padding: .5rem 0;
            }
            .foot{
                padding-top: .5rem;
                border-top: 1px dashed #dddddd;
                .reply-tag{
                    cursor: pointer;
                    margin-right: 0;
                }
            }
        }
    }
}
.to-comment{
    margin-bottom: 1rem;
    .ant-input{
        margin-top: .5rem;
        resize: none;
    }
    button{
        margin-top: .5rem;
    }
}