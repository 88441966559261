.gallery{
    .gallery-image{
        height: 200px;
        padding-right: .5rem;
        & ~ .ant-image-mask{
            margin-right: .5rem;
        }
    }
    @media ( max-width: 1200px ) {
        .gallery-image{
            height: 150px;
        }
    }
    @media ( max-width: 700px ) {
        .gallery-image{
            height: 100px;
        }
    }
}