.content-file{
    width: calc(min(100%,20rem) - 1.3rem);
    margin: .5rem .5rem .5rem .8rem;
    .title{
        font-weight: 600;
        font-size: 1.5rem;
        font-family: 'Ubuntu', sans-serif;
        margin-bottom: .5rem;
    }
    .content{
        .file-item{
            display: block;
            padding: .2rem 0 .8rem .8rem;
            position: relative;
            &:after{
                position: absolute;
                content: '';
                width: .5rem;
                height: .5rem;
                border-radius: .5rem;
                top: .5rem;
                left: -0.6rem;
                box-sizing: content-box;
            }
        }
    }
}