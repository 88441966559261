.share-bar{
    margin: .5rem;
    display: flex;
    .icon{
        display: flex;
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 2.5rem;
        margin-right: .5rem;
        background-color: rgba(0,0,0,.1);
        color: rgba(0,0,0,1);
        justify-content: center;
        align-items: center;
        font-size: 1.5rem;
        position: relative;
    }
    a:nth-child(4):after{
        content: '';
        position: absolute;
        top: 0;
        right: -0.5rem;
        width: 2px;
        height: 100%;
        background-color: #dddddd;
    }
    .copy{
        margin-left: .5rem;
        cursor: pointer;
    }
}