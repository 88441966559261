.image-component{
    margin: .5rem;
    width: calc(min(100%,40rem) - 1rem);
    .image-inside{
        width: 100%;
        background-color: rgba(0,0,0,.1);
        display: flex;
        justify-content: center;
        align-items: center;
        img{
            width: 100%;
        }
    }
    .caption{
        padding: 0 1rem;
        width: 100%;
        font-style: italic;
        text-align: center;
    }
}