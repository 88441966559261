.person-box{
    margin: .5rem;
    border: 1px solid #dddddd;
    width: calc(min(calc(100% - 1rem),22rem));
    padding: .5rem;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    .person-box-head{
        display: flex;
        color: #000000;
        .personal-information {
            display: flex;
            flex-flow: column;
            justify-content: flex-start;
            align-items: flex-start;
            .person-name{
                font-weight: 600;
                font-family: 'Ubuntu', sans-serif;
                font-size: 1.2rem;
                text-align: center;
                margin-bottom: 0;
            }
            .person-bio{
                font-size: .8rem;
            }
        }
        .person-photo{
            width: 8rem;
            height: 8rem;
            min-width: 8rem;
            min-height: 8rem;
            border-radius: 8rem;
            background-color: #333333;
            background-position: center;
            background-size: cover;
            margin-right: 1rem;
        }
    }
    .person-box-body{
        width: 100%;
        border-top: 1px solid #dddddd;
        margin-top: .5rem;
        padding-top: .2rem;
        ul{
            margin-bottom: 0;
            padding-left: 2rem;
            list-style-type: square;
            li{
                a{
                    color: #000000;
                }
            }
        }
    }
}