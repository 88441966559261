.content-listing{
    margin: .5rem;
    width: calc(100% - 1rem);
    .content{
        width: 100%;
        height: 8.5rem;
        display: flex;
        color: #000000;
        margin-bottom: 1rem;
        .content-image{
            width: 15rem;
            min-width: 15rem;
            height: 8.5rem;
            border-radius: .5rem;
            margin-right: 1rem;
            background-color: #333333;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
        }
        .content-info{
            display: flex;
            flex-flow: column;
            .info-title{
                font-size: 1.5rem;
                font-weight: 700;
                font-family: 'Ubuntu', sans-serif;
            }
            .info-text{
                flex: 1;
                overflow: hidden;
                position: relative;
                &:after{
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    content: '';
                    width: 100%;
                    height: 100%;
                    background: rgb(149,89,89);
                    background: linear-gradient(180deg, rgba(149,89,89,0) 0%, rgba(255,255,255,1) 100%);
                }
            }
        }
        @media ( max-width: 800px ) {
            flex-flow: column;
            height: 25rem;
            margin-right: 0;
            .content-image{
                width: 100%;
                flex: 1;
                margin-bottom: .5rem;
                min-height: 240px;
            }
            .content-info{
                flex: 1;
                overflow: hidden;
            }
        }
        @media ( max-width: 480px ) {
            .content-image{
                min-height: 200px;
            }
        }
    }
    .pagination{
        margin-top: 2rem;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
    }
}