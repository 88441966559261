@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&display=swap');.calendar{
    margin: .8rem .5rem .5rem .5rem;
    .calendar-item{
        display: flex;
        margin-bottom: 1.5rem;
        .date-box{
            line-height: 1;
            min-width: 6.5rem;
            width: 6.5rem;
            min-height: 8rem;
            height: 8rem;
            border: 4px solid #9fdae2;
            display: flex;
            flex-flow: column;
            justify-content: center;
            align-items: center;
            position: relative;
            .day{
                font-family: 'EB Garamond', serif;
                font-size: 2.5rem;
                font-weight: 700;
                margin-bottom: .5rem;
            }
            .month{
                font-size: 1rem;
                margin-bottom: .5rem;
            }
            .year{
                font-family: 'EB Garamond', serif;
                font-size: 1.2rem;
                font-weight: 700;
            }
            &::after, &:before{
                position: absolute;
                content: '';
                width: 4px;
                height: 20px;
                background: #9fdae2;
                top: -12px;
            }
            &::after{
                left: 20px;
            }
            &::before{
                right: 20px;
            }
        }
        .text-box{
            color: #000000;
            margin-left: 1rem;
            margin-top: .4rem;
            .box-title{
                line-height: 1.2;
                font-size: 1.5rem;
                font-weight: 600;
                padding-bottom: .4rem;
                border-bottom: 1px solid #dddddd;
                font-family: 'Ubuntu', sans-serif;
            }
            .box-description{
                margin-top: .4rem;
            }
        }
        @media ( max-width: 480px ) {
            .text-box{
                .box-title{
                    font-size: 1rem!important;
                    padding-bottom: .2rem!important;
                }
                .box-description{
                    font-size: .8rem!important;
                }
            }
        }
    }
}