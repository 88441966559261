.nav-menu{
    display: flex;
    .menu-item{
        font-size: 1rem;
        padding: 4px 15px;
        font-family: Asap, sans-serif;
        font-weight: 700;
        color: #333333;
        position: relative;
        &::after{
            transition: 300ms width;
            content: '';
            width: 0;
            height: 3px;
            position: absolute;
            bottom: 0;
            left: 0;
            background: linear-gradient(45deg, rgb(0, 159, 214) 0%, rgb(72, 181, 140) 100%);
            margin-left: 15px;
        }
        &:hover{
            &::after {
                width: calc(100% - 30px);
            }
        }
    }
    .drawer{
        overflow: auto;
        display: none;
        position: fixed;
        width: 100%;
        left: 0;
        background-color: #ffffff;
        border-top: 1px solid #dddddd;
        transition: 800ms top;
        .drawer-inside{
            width: 100%;
            padding: 0 1rem 2rem 1rem;
            position: absolute;
            top: 2rem;
            .drawer-menu-item{
                color: #333333;
                height: fit-content !important;
                font-size: 1.5rem;
                font-weight: 700;
                display: block;
                font-family: Asap, sans-serif;
                border-bottom: 3px solid #48b58c;
                text-align: center;
                width: fit-content;
                margin: 0 auto .5rem auto;
            }
            .cascader{
                display: flex;
                flex-flow: column;
                justify-content: center;
                align-items: center;
                .drawer-cascader-item{
                    color: #333333;
                    height: fit-content !important;
                    margin-bottom: .3rem;
                    font-size: .9rem;
                    &:last-child{
                        margin-bottom: .5rem;
                    }
                }
            }
        }
        &::-webkit-scrollbar-track {
            background-color: transparent;
        }
        &::-webkit-scrollbar {
            width: 4px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #a8a8a8;
        }
    }
}